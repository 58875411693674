canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#canvasDiv {
    // width: 90vw;
    // height: 90vh;
    height: 95vh;
}
